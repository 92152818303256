// FONTS SYSTEMS ---
@import url("https://fonts.googleapis.com/css?family=Rubik:300,300i,400,400i,500,500i,700,700i,900,900i&display=swap&subset=cyrillic,hebrew,latin-ext");
$font_title: "Segoe UI", sans-serif;
$font_text: "Rubik", sans-serif;
$font-path: "./fonts" !default;
@font-face {
  font-family: "Segoe UI";
  src: local("Segoe UI"), local("SegoeUI"),
    // url("./fonts/segmdl2.ttf") format("truetype"),
      // url("./fonts/segoepr.ttf") format("truetype"),
      // url("./fonts/segoeprb.ttf") format("truetype"),
      // url("./fonts/segoesc.ttf") format("truetype"),
      // url("./fonts/segoescb.ttf") format("truetype"),
      url("./fonts/segoeui.ttf") format("truetype");
  // url("./fonts/segoe-ui-368.ttf") format("truetype"),
  // url("./fonts/segoe-ui-612.ttf") format("truetype"),
  // url("./fonts/segoe-ui-635.ttf") format("truetype"),
  // url("./fonts/segoeuib.ttf") format("truetype"),
  // url("./fonts/segoeuii.ttf") format("truetype"),
  // url("./fonts/segoeuil.ttf") format("truetype"),
  // url("./fonts/segoeuisl.ttf") format("truetype"),
  // url("./fonts/segoeuiz.ttf") format("truetype"),
  // url("./fonts/seguibl.ttf") format("truetype"),
  // url("./fonts/seguibli.ttf") format("truetype"),
  // url("./fonts/seguiemj.ttf") format("truetype"),
  // url("./fonts/seguihis.ttf") format("truetype"),
  // url("./fonts/seguili.ttf") format("truetype"),
  // url("./fonts/seguisb.ttf") format("truetype"),
  // url("./fonts/seguisbi.ttf") format("truetype"),
  // url("./fonts/seguisli.ttf") format("truetype"),
  // url("./fonts/seguisym.ttf") format("truetype");
}

// COLOR SYSTEMS ---
$primary: #1e53a3;
$secundary: #e35205;
$oscuro: #333333;
$fondo: #eeeef1;
$azul-oscuro: #0e2d5c;
$azul-claro: #4872b2;
$gris-claro: #f2f2f2;
$gris-medio: rgba(238, 238, 238, 0.548);
$gris-mediotexto: #a3a3a3;
$gris-oscuro: #707070;
$gris-titulo: #808080;
$gris-negrotexto: #1a2632;
$blanco: #ffffff;
$negro: #000000;
$verde-excel: #1d6f42;
$rojo: #db0000;

//Media Queries
$breakpoint-500px: 500px;

// General colors
$c_orange: #e35205;

// SHADOWS
// $shadow_tabs: 0px 0px 99px rgba(51, 51, 51, 0.5);
$shadow_tabs: 0px 0px 40px rgba(51, 51, 51, 0.35);

// <i className="fas fa-user"/>        ---  User
// <i className="fas fa-eye"/>         ---  Ver
// <i className="fas fa-pen"/>         ---  Editar
// <i className="fas fa-trash-alt"/>   ---  Eliminar
// <i className="fas fa-ban"/>         ---  Cancelar
// <i className="far fa-file-alt"/>    ---  Documento
// <i className="fas fa-mouse-pointer"/> -  Seleccionar
// <i className="fas fa-user-friends"/>  -  Cambio operador
// <i className="fas fa-bus-alt"/>     ---  bus-origen
// <i className="fas fa-map-marker-alt"/>-  bus-destino
// <i className="fas fa-exchange-alt"/> --  change
