
@import "../../../theme/main.scss";


.wrapper__conteo{
  margin-bottom: 5rem;
  span{
    color: red !important;
    font-weight: bold;
  }
}


.expand-title{
  margin-bottom: 2rem;
}

