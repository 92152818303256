@import '../../../theme/_var';

.wrapper_uploadlogo{
  .content_uploadlogo{
    margin: .5rem 0 2.5rem;
    padding:  2rem 0;
    background: $fondo;
    p{
      color: #aaaaaa;
      font-size: .9rem;
      padding: 0 .5rem;
    }
    .box_logo{
      margin: 1.25rem 0;
      figure img{
        height: 200px;;
      }
    }
  }
}