@import "../../../theme/main.scss";


.poprev.tooltip {
  .tooltip-inner {
    max-width: 50rem;
    /* If max-width does not work, try using width instead */
    width: 50rem;
    border-radius: 0rem;
    background-color: $blanco;
    color: $oscuro;
    border: $gris-oscuro 1px solid;
    padding-top: 0.5rem;
    padding-bottom: 0.7rem;
    font-style: italic;
  }
}

#get-back {
  color: #E35205;
  font-size: 45px;
  margin-right: 24.5em;
  margin-top: 1rem;
  cursor: pointer;
}

.provider-wrapper{
  width: 1300px;
  margin: 0 auto;
  margin-top: 3rem;
  background-color: white; 
}

.form-provider{
  margin-top: 3rem;
  background-color: white; 
}

#inv-apld {
  // table-layout: fixed;
  // width: 100%;
  // min-width: 730px;
  font-size: 14px;
}

.label-provider{
  color: #3c454c;
}

.register-form-row{
  margin-bottom: 2rem;
}

.provider-inputs{
  height: 2.8rem !important;
  border: none !important; 
  background-color: #EEEEF1 !important;
}

.provider-inputs::placeholder {
  color: #D7D7D7 !important;
}

.send-button{
  margin-top: 1rem !important;
  margin-bottom: 2rem !important;
  font-size: 16px !important;
}


// #inv-apld td {
//   width: 14.2857%;
// }

@media only screen and (max-width: 1079px) { 
  #inv-apld {
    font-size: 11px;
  }
}