@import "../theme/var";
@import "node_modules/bootstrap/scss/bootstrap.scss";
@import "~@sweetalert2/theme-minimal/minimal.scss";

html,
body {
  background: $fondo;
  color: $oscuro;
}
.cursor{
  cursor: pointer;
}
body,
button,
input,
select,
textarea {
  font-family: $font_title !important;
  text-align: center;
  line-height: 1.25;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font_title;
  line-height: 1;
  font-weight: 700;
}

a {
  color: $gris-medio;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    text-decoration: none;
    color: #ffffff;
  }
  &:visited {
    color: $gris-medio;
  }
}


.pendiente-debs{
  background-color: #ffff0052 !important;
}
.pagada-debs{
  background-color: #069a0659 !important;
}
.cancelada-inactiva-debs{
  background-color: #ff00004d !important;
}

.swal2-close{
  line-height: .8 !important;
}

.c_secundary{
  color: $secundary
}

//GRAND VISION ESTILOS

.gresponsive-tables{
    table-layout: fixed;
    width: 100%;
    min-width: 730px;
}

form {
  label {
    color: $primary;
    font-weight: 400;
    span{
      color: red;
      font-weight: bold;
    }
  }
}
input {
  &.form-control {
    border-radius: 0rem !important;
    &:hover,
    &:focus,
    &:active{
      outline: none;
      box-shadow: none !important;
    }
  }
}
.input-group {
  .input-group-text {
    border-radius: 0 !important;
    background: $blanco !important;
    margin: 0;
  }
}

.form-control {
  &:hover,
  &:focus{
    outline: none;
    box-shadow: none !important;
    background: $gris-claro;
  }
}

.form-group {
  margin: 0.3rem 0rem 0.3rem 0rem;
  padding: 0rem;
}

.custom-radio,
.custom-checkbox{
  .custom-control-label{
    padding-left: .75rem;
    color:  $negro;
    cursor: pointer;
    &::before{
      border-radius: 50% !important;
      width: 1rem;
      height: 1rem;
      background: #fff;
      left: -.5rem;
      top: .1rem;
    }
    &::after{
      top: .1rem;
      left: -.5rem;
      border-radius: 50% !important;
      width: 1rem;
      height: 1rem;
    }
  }
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after,
.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
  background: $secundary;
  background-image: none;
}


.boxes_tooltip{
  position: relative;
  padding: 0 !important;
  border: 0 !important;
  &>.custom-control{
    width: 100%;
  }
  .boxes_tooltip_ico{
    position: absolute;
    top: 9px;
    right: 10px;
    cursor: pointer;
    font-size: 1rem;
  }
  .boxes_tooltip_info{
    display: none;
    position: absolute;
    top: 33px;
    left: 2.5%;
    width: 95%;
    background: #ffffff;
    box-shadow: 1px -1px 10px 3px rgba(0, 0, 0, 0.25);
    font-size: .90rem;
    padding: .5rem .6rem;
    z-index: 99;
    &::before{
      content: '';
      position: absolute;
      top: -8px;
      right: 5px;
      width: 0;
      height: 0;
      border-left: 7px solid transparent;
      border-right: 7px solid transparent;
      border-bottom: 8px solid #ffffff;
    }
    &>p{
      margin-bottom: .5rem;
    }
    &.show_tool{
      display: block;      
    }
  }
}

.class-tooltip{
  background-color: white;
  opacity: 2 !important;
  font-size: .90rem;  
  // border: solid 0.5px !important;
  border-color: #CCCCCC !important;
}

.css-1sgc003-SelectContainer{
  height: 3.25rem !important;
}
.col_bar_search{
  &>.container{
    padding: 0;;
  }
}

.box_icons_tables{
  .i_icons{
    font-size: 1.15rem !important;
    margin: 0 .25rem;
    cursor: pointer;
    color: $primary;
    &:hover {
    color:gray;
  }
  }
}

.box_icons_comment{
  .i_icons{
    font-size: 1.5rem !important;
    margin: 0 .25rem;
    cursor: pointer;
  }
}

.btn-primary {
  color: $primary;
  text-transform: uppercase;
  border: $secundary 2px solid;
  background: $blanco;
  border-radius: 20px;
  padding-left: 1rem;
  margin-top: 1rem;
  padding-right: 1rem;
  text-align: center;
  font-size: large;
  font-weight: bolder;
  margin-bottom: 0.3rem;
  font-family: $font_title;
  &.btn_small{
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 1rem !important;
  }
  &:disabled {
    color: $gris-oscuro;
    background-color: $blanco;
    border-color: $gris-oscuro;
  }
  .fas,
  .far {
    font-size: xx-large;
  }
  &:hover,
  &:focus,
  &:active {
    a {
      color: $blanco;
    }
  }
  a {
    color: $primary;
    &:hover,
    &:focus,
    &:active {
      color: $blanco;
    }
  }
}
.btn-secondary {
  color: $secundary !important;
  text-transform: uppercase;
  border: $primary 2px solid;
  background: $blanco;
  border-radius: 20px;
  padding-left: 3rem;
  padding-right: 3rem;
  text-align: center;
  font-size: larger;
  font-weight: 700;
  margin-bottom: 0.3rem;
  &:hover {
    background-color: $gris-claro !important;
    color: $gris-oscuro !important;
  }
  .fas,
  .far {
    font-size: xx-large;
  }
}

@media (max-width: $breakpoint-500px) {
  .container,
  .container-fluid {
    overflow: visible;
  }
  .btn-primary,
  .btn-secondary {
    width: 15vmax;
    height: 5vmax;
    min-width: 200px;
    padding: 0.3vmax;
    font-size: 2.3vmax;
  }
}

// .custom-control-label {
//   &::before {
//     border: #e35205 2px solid !important;
//     border-radius: 0rem !important;
//   }
// }

//NAVBAR
a.dropdown-toogle.nav-link,
.navbar-dark .navbar-nav .nav-link {
  color: $blanco !important;
}
.nav-link {
  font-size: 1.25rem;
  color: $blanco;
}

.dropdown-menu.show {
  border-radius: 0rem !important;
  background-color: $azul-claro !important;
  margin-top: 0rem;
  width: 100%;
  padding: 0;
  a{
    text-transform: uppercase;
    text-align: center;
    color: #ffffff;
    padding: 1.25rem 0;
    display: inline-block;
    width: 100%;
    border-top: 3px solid #e9ecef;
    &:hover,
    &:focus {
      background-color: $primary;
      color: $blanco;
    }
  }
}

//TABLAS
.icons {
  margin-left: 0.6rem;
  cursor: pointer;
  font-size: 1.8rem;
}
.filtroselect {
  border-radius: 0rem !important;
}

.table-bordered {
  background-color: $blanco;

  th {
    color: $blanco;
    font-weight: 400;
  }

  td {
    color: #777777;
  }
  &.table-hover tr:hover {
    color: #000000;
    background-color: transparent;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.25);
    .estado-aud {
      cursor: pointer;
      color: #333333;
      transform: scale(1.03);
    }
    td {
      color: $negro;
      background: #ffffff;
      &.datovacio {
        color: $gris-mediotexto !important;
      }
    }
  }
}
.estado-aud {
  font-size: x-large;
  color: #808080;
  &:hover {
    color: #303030;
    transform: scale(1.007);
  }
  &.revisado {
    color: $secundary;
  }
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, $secundary 50%),
    linear-gradient(135deg, $secundary 50%, transparent 50%);

  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border: none;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, $primary 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, $primary 50%);

  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: $secundary;
  outline: 0;
  border: none;
}

.listanumeros {
  width: 4rem !important;
  margin-left: 1.3rem;
  padding: 0.2rem 0.2rem 0.2rem 1rem;
  border-radius: 0rem !important;
  border: none;
  background-color: $gris-claro;
  font-weight: 700;
  color: $negro;
  font-size: .85rem;
  height: 35px;
}
.paginador {
  background-color: $blanco;
  padding: 0.3rem;
  margin: 0rem 0rem 1rem 0rem;
  height: 47px;
  label {
    padding-top: 0.3rem;
    padding-left: .5rem;
  }
  select {
    margin-top:.12rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    &::-ms-expand {
      display: none;
    }
  }
}
.titulos {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

//MODAL

.modal-content {
  border-radius: 0rem;
  .modal-header,
  .modal-header,
  .modal-close,
  .modal-footer {
    border: 0rem !important;
  }
}

.swal2-container.swal2-shown {
  background-color: rgba(10, 10, 10, 0.86);
  .swal2-header {
    border-bottom: 2px solid $secundary;
  }
  .swal2-title {
    color: #222222;
    font-size: 1.75rem;
    max-width: 500px;
    // word-break: break-all;
  }
  .swal2-icon {
    margin: 0 auto 1rem;
  }
  .swal2-popup {
    border-radius: 0;
  }
  .swal2-styled {
    border: 0 !important;
    border-radius: 0;
    background: $primary !important;
    &:focus {
      outline: 0;
      box-shadow: 0 0 0 2px #fff, 0 0 0 4px $primary;
    }
  }
}

::-webkit-scrollbar {
  width: 9px;
}
::-webkit-scrollbar-thumb {
  background: #bbbbbb !important;
}

.m-0 {
  margin: 0 !important;
}
.mt-0 {
  margin-top: 0 !important;
}
.mt-1 {
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 2rem !important;
}
.mt-3 {
  margin-top: 3rem !important;
}
.mt-4 {
  margin-top: 4rem !important;
}
.mt-5 {
  margin-top: 5rem !important;
}

.mb-0 {
  margin-bottom: 0;
}
.mb-1 {
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 4rem !important;
}
.mb-5 {
  margin-bottom: 5rem !important;
}

.p-0 {
  padding: 0 !important;
}

.bshadow {
  -webkit-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.15);
}

.trans {
  -webkit-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.minWidth {
  min-width: 125px;
}

.section {
  padding: 0 1rem;
  &.ptop {
    padding: 2rem 1rem;
  }
}

.modal {
  text-align: center;
  padding: 1rem;
  .inputGroup {
    width: 100%;
    margin: 0.35rem 0;
  }
  .ttl_modals {
    font-size: 1.75rem;
    font-weight: 500;
    padding-bottom: 1rem;
    border-bottom: 2px solid $secundary;
  }
  .modal-close {
    background: $primary;
  }
  .modal-content {
    padding: 1rem;
    background: #ffffff;
  }
}

.hero {
  .hero-body {
    padding: 3rem 0 1.75rem;
    .title {
      color: $oscuro;
      font-weight: 600;
      span {
        padding-right: 1rem;
        display: inline-block;
        padding-bottom: 0.75rem;
      }
    }
  }
}

// TITLES SECTIONS **
.box_title_sections {
  .ttl_sections {
    font-size: 2rem;
    font-weight: 600;
  }
}

.btns_icons {
  color: $primary;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
}

.color_primary {
  color: $primary;
}

.button {
  color: $primary;
  border: $secundary 2px solid !important;
  background: $blanco !important;
  border-radius: 20px;
  width: 100%;
  max-width: 150px;
  height: 45px;
  font-weight: 600;
  color: $primary;
  border: 0;
  -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.5);
  &:before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 0px;
    background: rgba(255, 255, 255, 0.075);
    -webkit-transition: all 0.2s linear;
    transition: all 0.2s linear;
  }
  &:focus,
  &:active,
  &:hover {
    color: #ffffff;
    background: $primary !important;
    border: $primary !important;
    &:before {
      height: 100%;
    }
  }
  &.primary {
    background: $primary;
  }
  &.borders {
    // background: transparent;
    color: $primary;
    &:hover {
      color: $oscuro;
    }
  }
}

.go_back {
  a {
    color: $oscuro;
    font-weight: 500;
    font-size: 1rem;
    .fas {
      -webkit-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
      -moz-text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
      text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.35);
      color: $primary;
      font-size: 1.25rem;
      top: 0.15rem;
      position: relative;
    }
  }
}

.box_notifications,
.wrapper_actions {
  .btn_notifications,
  .btns_icon_tables {
    margin-right: 0.25rem;
    cursor: pointer;
    text-align: center;
    display: inline-block;
    font-size: 0.9rem;
    height: 25px;
    border-radius: 50%;
    width: 25px;
    background: $primary;
    color: #fff;
    padding-top: 3.5px;
    // padding-left: 1px;
  }
}

.field {
  .label {
    font-weight: 400;
    text-align: center;
    font-size: 0.95rem;
  }
  .control {
    .input,
    .textarea,
    .select {
      width: 100%;
      border-radius: 0;
      background: $fondo;
      border: 1.5px solid $fondo;
      height: 50px;
      text-align: center;
      box-shadow: none;
      &:focus {
        border: 2px solid $secundary;
        outline: none;
      }
    }
    &.box_select {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: calc(46% - 4px);
        right: 0.77rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 8px 0 8px;
        border-color: $primary transparent transparent transparent;
        z-index: 5;
        pointer-events: none;
      }
    }
    &.box_select_dates {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: calc(46% - 4px);
        right: 0.77rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 8px 0 8px;
        border-color: $primary transparent transparent transparent;
        z-index: 5;
        pointer-events: none;
      }
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        right: 1.5rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 15px 20px 15px 20px;
        border-color: $fondo;
        z-index: 4;
        pointer-events: none;
      }
    }
    &.box_select_dates_background_white {
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: calc(46% - 4px);
        right: 0.77rem;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 12px 8px 0 8px;
        border-color: $primary transparent transparent transparent;
        z-index: 5;
        pointer-events: none;
      }
      &::before {
        content: "";
        position: absolute;
        top: 4px;
        right: 0.1rem;
        width: 45px;
        height: 40px;
        background-color: #ffffff;
        z-index: 4;
        pointer-events: none;
      }
    }
    .select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 0 0.35rem;
      text-align-last: center;
      option {
        background: #ffffff;
        color: $secundary;
        height: 50px;
        padding: 5rem 0;
      }
    }
    .autocomplete-dropdown-container {
      .suggestion-item--active {
        background-color: #3273dc;
        cursor: pointer;
      }
      .suggestion-item {
        background-color: #ffffff;
        cursor: pointer;
      }
    }
  }
}

.flex_container {
  display: -webkit-flex;
  display: flex;
  align-items: center;
  -webkit-align-items: center;
}

.placeholder-color {
  &::placeholder {
    color: #d60909;
  }
}

// TABLES  **
.wrapper_head_tables{
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: .25rem 0;
  .box_fecha{
    min-width:275px;
    padding: 0 1rem;
    font-weight: bold;
  }
  .box_filtro{
    border: none;
  }
  .box_options{
    min-width: 200px;
    padding: 0 1rem;
    .btn_download{
      background-color:$verde-excel;;
      color: #ffffff;
      border: 0;
      padding: .5rem;
      outline: 0;
      width: 100%;
    }
  }
  .box_filtro{
    .btn_filtro #filtro-columnas{
      border-color: none;
    }
  }
}


.wrapper_tables {
  .table {
    width: 99.5%;
    min-width: 775px;
    & > thead {
      background: $secundary;
      th {
        font-weight: 400;
        border: 0;
        text-align: center;
        color: #ffffff;
        padding: 0.75rem 0.5rem;
        border-right: 2px solid #dddddd;
        &:last-child {
          border-right: 0;
        }
      }
    }
    & > tbody {
      td {
        text-align: center;
        border: 2px solid #dddddd;
        font-size: 0.85rem;
      }
    }
  }
}

.cabecera {
  background: $fondo !important;
  th {
    color: $oscuro !important;
    font-weight: 600 !important;
  }
}

.cuerpo-de-tabla {
  background: $fondo !important;
  td {
    text-align: center;
    border-left: none !important;
    border-right: none !important;
    border-top: 15px solid $fondo !important;
    border-bottom: 15px solid $fondo !important;
    font-size: 0.85rem;
    background-color: #ffffff !important;
    box-shadow: 0 0px 2px rgba(0, 0, 0, 0.4) !important;
    &:first-child {
      border-left: $secundary 10px solid !important;
    }
  }
}



.wrapper_input_bg{
  .form-group.row {
    .col-form-label{
      background-color: $azul-oscuro;
      color:#fff
    }
    .col-form-control{
      padding: 0;
    }
  } 
}

.input-group > .input-group-prepend > .input-group-text.escan{
    background: #e35205 !important;
    color: #fff;
}

// ACCORDION **
.accordions_wrapper {
  .accordion_item {
    background: #ffffff;
    margin-bottom: 1rem;
    font-size: 0.9rem;
    .accordion_header {
      cursor: pointer;
      & > div {
        vertical-align: middle;
        display: inline-block;
      }
      .accordion_img_item {
        line-height: 0;
        width: 115px;
        text-align: center;
        padding: 0.35rem 0.25rem;
        img {
          height: 32px;
        }
      }
      .accordion_title_item {
        height: 43px;
        font-weight: 600;
        padding-top: 0.85rem;
        padding-left: 0.5rem;
      }
      .accordion_options_item {
        padding-top: 0.5rem;
      }
    }
    .accordion_body {
      display: none;
      padding: 0.75rem;
      .accordion_content {
        .columns {
          border-top: 2px solid #dddddd;
          .container-text-contact {
            width: auto;
            max-width: 500px;
            word-wrap: break-word;
          }
          .container-text-email {
            width: auto;
            max-width: 500px;
            word-wrap: break-word;
          }
          .label_accordion {
            font-weight: 700;
          }
        }
      }
    }
    .accordion_header.is-active {
      & + .accordion_body {
        display: block;
      }
      .accordion_title_item {
        color: $secundary;
      }
    }
  }
}

// COMPONENTS LOGIN && CONTACTO **
.wrapper_public {
  background: url(/imgs/bglogin.png) no-repeat bottom center;
  background-size: cover;
  color: #ffffff;
  &:before {
    content: "";
    height: 85vh;
    width: 100vw;
    position: absolute;
    background: rgba(8, 94, 99, 0.95);
    background: -moz-linear-gradient(
      top,
      rgba(8, 94, 99, 0.95) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -webkit-gradient(
      left top,
      left bottom,
      color-stop(0%, rgba(8, 94, 99, 0.95)),
      color-stop(100%, rgba(255, 255, 255, 0))
    );
    background: -webkit-linear-gradient(
      top,
      rgba(8, 94, 99, 0.95) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -o-linear-gradient(
      top,
      rgba(8, 94, 99, 0.95) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: -ms-linear-gradient(
      top,
      rgba(8, 94, 99, 0.95) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: linear-gradient(
      to bottom,
      rgba(8, 94, 99, 0.95) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#085e63', endColorstr='#ffffff', GradientType=0 );
    z-index: 0;
  }
  .box_content_public {
    position: relative;
    z-index: 1;
    .box_logo img {
      height: 125px;
    }
    .control {
      .input,
      .textarea,
      .select {
        width: 100%;
        border-radius: 0;
        background: rgba(8, 94, 99, 0.5);
        border: 1.5px solid #ffffff;
        color: #ffffff;
        &::placeholder {
          color: rgba(255, 255, 255, 0.75);
          text-align: center;
        }
      }
      .input_error {
        background: rgba(99, 8, 8, 0.5);
        border: 1.5px solid #d60909;
      }
      .icons_pass {
        position: absolute;
        top: calc(50% - 12px);
        right: 10px;
        color: $primary;
        cursor: pointer;
        text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.65);
      }
    }
    .input-feedback {
      text-shadow: 1px 1px 4px rgba(0, 0, 0, 0.4);
      font-size: 0.85rem;
      text-align: center;
      .fas {
        font-size: 0.75rem;
      }
    }
  }
}

.actual-text {
  color: #e8461d;
  font-weight: bold;
}

.datepick {
  width: 100% !important;
  background-color: $fondo;
  display: flex;
  align-content: center;
  justify-content: center;
}

.date-calendar {
  z-index: 6 !important;
  width: 100% !important;
  margin: 0 auto;
}

// ERROR_INPUT_GLOBALES **
.control {
  .input,
  .textarea,
  .select {
    width: 100%;
    border-radius: 0;
  }
  .input_error {
    background: rgba(99, 8, 8, 0.5) !important;
    border: 1.5px solid #d60909 !important;
    color: #ffffff;
    &::placeholder {
      color: rgba(255, 255, 255, 0.75);
      text-align: center;
    }
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      right: 0;
      width: 100px;
      height: 45px;
      background-color: #ab7d7d !important;
      border: none;
      z-index: 4;
      pointer-events: none;
    }
  }
  .icons_pass {
    position: absolute;
    top: calc(50% - 12px);
    right: 10px;
    color: $primary;
    cursor: pointer;
  }
}
.wrapper_public_error {
  z-index: 0;
}
.box_content_public {
  position: relative;
  z-index: 1;
  .control {
    .input,
    .textarea,
    .select {
      width: 100%;
      border-radius: 0;
    }
    .input_error {
      background: rgba(99, 8, 8, 0.5) !important;
      border: 1.5px solid #d60909 !important;
      color: #ffffff;
      &::placeholder {
        color: rgba(255, 255, 255, 0.75);
        text-align: center;
      }
    }
    .icons_pass {
      position: absolute;
      top: calc(50% - 12px);
      right: 10px;
      color: $primary;
      cursor: pointer;
    }
  }
  .input-feedback {
    font-size: 0.85rem;
    text-align: center;
    .fas {
      font-size: 0.75rem;
    }
  }
}

// TABS GENERALES **
.wrapper_tabs {
  margin-bottom: 3rem;
  .tabs {
    margin-bottom: 0;
    li {
      font-size: 1.25rem;
      font-weight: 500;
      cursor: pointer;
      &:hover .tabs_name {
        background: #4e4d4d7d;
      }
      & .tabs_name {
        background: #4e4d4d5d;
        color: #ffffff;
        border-color: #abc6c7;
        display: block;
        padding: 0.5rem 1rem;
        text-align: center;
      }
      &.is-active .tabs_name {
        background: $secundary;
        -webkit-box-shadow: $shadow_tabs;
        -moz-box-shadow: $shadow_tabs;
        -o-box-shadow: $shadow_tabs;
        box-shadow: $shadow_tabs;
        border-color: $secundary;
        color: #ffffff;
      }
    }
  }
  .tabs_body {
    .tabs_content {
      background: #ffffff;
      -webkit-box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.85);
      -moz-box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.85);
      box-shadow: 0px 2px 10px -5px rgba(0, 0, 0, 0.85);
      padding: 2rem 1rem;
      .tabs_info {
        margin: 0 auto;
        max-width: 790px;
      }
    }
  }
}

// CHECKBOX && RADIO-BOTTOM GENERALES **
.inputGroup {
  background-color: #fff;
  display: inline-block;
  width: 47%;
  margin: 0 0.25rem;
  position: relative;
  .label_styl {
    padding: 15px;
    background: #e9e9e9;
    width: 100%;
    display: block;
    text-align: center;
    color: #3c454c;
    cursor: pointer;
    position: relative;
    z-index: 0 !important;
    transition: color 200ms ease-in;
    overflow: hidden;
    &:hover {
      background: #d8d8d8;
    }
    &:before {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      content: "";
      background-color: $secundary;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%) scale3d(1, 1, 1);
      transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1);
      opacity: 0;
      z-index: -1;
    }
  }
  .inputs_styl:checked ~ .label_styl {
    color: #fff;
    &:before {
      transform: translate(-50%, -50%) scale3d(56, 56, 1);
      opacity: 1;
    }
    &:after {
      background-color: #54e0c7;
      border-color: #54e0c7;
    }
  }
  .inputs_styl {
    width: 32px;
    height: 32px;
    order: 1;
    z-index: 2;
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    visibility: hidden;
  }
}


// Forms
.asterisk {
  color: $c_orange;
  font-weight: 700;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__header {
  background-color: $secundary;
  color: $fondo;
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: $fondo;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: $fondo;
  }
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  font-weight: 600;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: $primary;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  border-left-color: $primary;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: $primary;
}

.react-datepicker__triangle {
  border-bottom-color: $secundary !important;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  color: $oscuro;
}

.react-datepicker__month-option--selected {
  color: $primary;
  font-weight: bolder;
}

.react-datepicker__navigation--previous {
  border-right-color: $primary;
}

.react-datepicker-time__header {
  color: $secundary !important;
}

.react-datepicker-time__header::before {
  content: "Hora";
  color: #ffffff;
  background: $secundary;
  width: 100%;
  height: 100%;
  margin-left: 15px;
}

.react-datepicker__day--selected {
  background-color: $primary;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected {
  background-color: $primary;
}

.ContentImageWithoutContent {
  margin: 2rem 1rem;
  .imageWithoutContent {
    width: 300px;
    margin-bottom: 1rem;
  }
}

.texto-izquierda {
  text-align: left !important;
  padding-top: 0.7rem;
  &::placeholder {
    text-align: left !important;
  }
}


@media screen and (max-width: 1000px) {
  .menu-general.navbar,
  .menu-sucursal.navbar {
    padding: .75rem  !important;
  }
}

@media screen and (max-width: 820px) {
  .accordions_wrapper {
    .accordion_item {
      .accordion_content {
        .columns {
          .column {
            width: 25%;
          }
        }
      }
    }
  }
}

@media screen and (max-width:768px) {
  .wrapper_head_tables{
    background: #ffffff;
    display: flex;
    justify-content:center;
    flex-direction: column;
    align-items: center;
    padding: .25rem 0;
    .box_fecha{
      min-width:100%;
    }
    .box_search{
      padding: 0 1rem;
    }
    .box_options{
      padding: 0 1rem;
    }
  }
  
}



@media screen and (max-width: 768px) {

  .wrapper_questionnaire {
    .box_list_questions{
      margin-top: 2rem;
    }
    .box_add_questions .box_observations_question .observations_open .observations_open_answer{
      display: flex;
    }
  }
}
@media screen and (max-width: 680px) {
  .section {
    padding: 0 1rem 3rem;
    &.ptop {
      padding: 2rem 1rem;
    }
  }
  .accordions_wrapper {
    .accordion_item {
      .accordion_content {
        .columns {
          .column {
            width: 50%;
          }
        }
      }
  .wrapper_questionnaire .box_add_questions .box_observations_question .observations_open .observations_open_answer{
    display: block;
  }
}

@media screen and (max-width: 580px) {

  .wrapper_questionnaire {
    .box_add_questions .box_observations_question .observations_open .observations_open_answer{
      display: block;
    }
  }
}

@media screen and (max-width: 485px) {
  .accordions_wrapper {
    .accordion_img_item {
      display: none !important;
    }
    .accordion_item {
      .accordion_content {
        .columns {
          .column {
            width: 100%;
            padding: 0.35rem 0.75rem;
          }
        }
      }
    }
  }
  .swal2-container.swal2-shown {
    .swal2-styled {
      width: 85%;
    }
  }
}

@media screen and (max-width: 390px) {
  .wrapper_questionnaire .box_add_questions .box_observations_question{
    display: block;
  }
    .observations_open{
      margin-top: .75rem;
    }
  }}}

.descripcion-inv{
  width: 20rem !important;
}

.complement-input{
  width: 8rem !important;
}

.select-icon{
  color: red !important;
}