.sweet-loading {
  z-index: 9999;
  position: fixed;
  width: 100% !important;
  height: 120% !important;
  background-color: rgba(0, 5, 17, 0.623) !important;
  margin-top: -5rem;

  & > * {
    position: absolute !important;
    left: 50% !important;
    margin-left: -50px !important;
    top: 50% !important;
    margin-top: -50px !important;
  }
}
