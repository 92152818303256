@import "../../../theme/main.scss";

.wrapper_footer_logout{
  position: fixed;
  background: #ffffff;
  padding: .75rem 1rem;
  bottom: 1.25rem;
  right:0;
  border-radius: .5rem 0 0 .5rem;
  -webkit-box-shadow: -2px 0px 15px -1px rgba(0,0,0,0.1);
  -moz-box-shadow: -2px 0px 15px -1px rgba(0,0,0,0.1);
  box-shadow: -2px 0px 15px -1px rgba(0,0,0,0.1);
  cursor: pointer;
  .img_footer_logout{
    height: 35px;
  }
}

.cerrar-sesion{
  background-color: $primary;
  color: white;
}

@media screen and (max-width: 990px) {
  .wrapper_footer_logout{
    background: #ffffff;
    padding: .5rem;
    top: 8px;
    right: 8px;

    border-radius: .25rem;
    bottom: auto;
    box-shadow: none;
    .img_footer_logout{
      height: 30px;
    }
  }
}

span{
  font-family: $font_title;
  
}