@import "../../../../theme/main.scss";

.margin-navbar {
  margin: 0rem 0rem 0rem 0rem !important;
  padding: 0rem !important;
}

.menu-sucursal.navbar {
  padding: 0rem !important;
  .nav-link {
    width: calc(100% / 5);
    border-left: $azul-oscuro 1px solid;
    margin: 0rem;
    padding: 1rem;
    color: $blanco;
    font-size: 1.1rem !important;
    text-transform: uppercase;
    @media screen and (max-width: 1000px) {
      width: 100%;
    }
    &:hover,
    &:focus,
    &:active {
      background-color: $azul-oscuro;
      color: $blanco;
    }
  }
  .navbar-nav {
    width: 100%;
  }
  .navbar-dark .navbar-nav .nav-link {
    &:hover,
    &:focus {
      color: $blanco;
    }
  }
}

.colorSeleccion {
  background-color: #1A1F5E !important;
}