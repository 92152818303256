@import "../../theme/main.scss";


.logologin {
  width: 50%;
}
.fondo-login {
  width: 65%;
  right: 0;
  text-align: center;
  margin: 0 auto;
  margin-top: 1rem;
}

.input-login{
  text-align: left;
}

.top-login{
  margin-top: -2rem;
}

.login {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 400;
    color: $gris-titulo;
    font-size: 30px;
    font-family: $font_text;
  }

  background: $blanco;
  width: 90%;
  padding: 2rem;
  margin: 0 auto;
  box-shadow: $shadow_tabs;
  text-align: center;
  justify-content: center;
  .form-label {
    margin-top: 0.7rem;
    font-size: larger;
    font-family: $font_text;
    font-weight: 400;
    font-size: 1.3rem;
  }
  input {
    padding-top: 1rem;
    padding-bottom: 1rem;
    line-height: 1.5rem;
    height: auto;
    font: $font_text;
    &::placeholder {
      font-style: italic;
    }
  }
  .ojito {
    cursor: pointer;
  }
}

.checklogin {
  label {
    color: $secundary;
    padding-top: 0.1rem;
  }
}



// @media screen and (max-width: 1320px) {
//   .fondo-login {
//     height: 80%;
//     display: block;
//     margin-left: auto;
//     margin-right: 0;
//     text-align: center;
//   }
// }
@media screen and (max-width: 766px) {
  .fondo-login {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  .login {
    width: 90%;
    
  }
}